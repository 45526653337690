/**
 *  ? Tip:
 *
 * For Actual Node.js - Role Based Authorization Tutorial with Example API
 * Refer: https://jasonwatmore.com/post/2018/11/28/nodejs-role-based-authorization-tutorial-with-example-api
 * Running an Angular 9 client app with the Node.js Role Based Auth API
 */

import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { delay, mergeMap, materialize, dematerialize } from "rxjs/operators";

import { User, Role } from "app/auth/models";

// Users with role
const users: User[] = [
  {
    identifier_type: "CC",
    identifier: 1,
    email: "admin@demo.com",
    password: "admin",
    name: "John",
    last_name: "Doe",
    avatar: "avatar-s-11.jpg",
    role: Role.Administrador,
    identifier_business: 1,
    identifier_campus: 1,
    status: "active",
    identifier_role: 1,
    name_business:""
  },
  {
    identifier_type: "CC",
    identifier: 2,
    email: "client@demo.com",
    password: "client",
    name: "Nataly",
    last_name: "Doe",
    avatar: "avatar-s-2.jpg",
    role: Role.User,
    identifier_business: 1,
    identifier_campus: 1,
    status: "active",
    identifier_role: 1,
    name_business:""
  },
  {
    identifier_type: "CC",
    identifier: 3,
    email: "user@demo.com",
    password: "user",
    name: "Rose",
    last_name: "Doe",
    avatar: "avatar-s-3.jpg",
    role: Role.User,
    identifier_business: 1,
    identifier_campus: 1,
    status: "active",
    identifier_role: 1,
    name_business:""
  },
];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;

    // wrap in delayed observable to simulate server api call
    return of(null).pipe(mergeMap(handleRoute));
    // .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
    // .pipe(delay(500))
    // .pipe(dematerialize());

    function handleRoute() {
      switch (true) {
        case url.endsWith("/users/authenticate") && method === "POST":
          return authenticate();
        case url.endsWith("/users") && method === "GET":
          return getUsers();
        case url.match(/\/users\/\d+$/) && method === "GET":
          return getUserById();
        default:
          // pass through any requests not handled above
          return next.handle(request);
      }
    }

    // route functions

    function authenticate() {
      const { email, password } = body;
      const user = users.find(
        (x) => x.email === email && x.password === password
      );
      if (!user) return error("Username or password is incorrect");
      return ok({
        id: user.identifier,
        email: user.email,
        firstName: user.name,
        lastName: user.last_name,
        avatar: user.avatar,
        role: user.role,
        token: `fake-jwt-token.${user.identifier}`,
      });
    }

    function getUsers() {
      if (!isAdmin()) return unauthorized();
      return ok(users);
    }

    function getUserById() {
      if (!isLoggedIn()) return unauthorized();

      // only admins can access other user records
      if (!isAdmin() && currentUser().identifier !== idFromUrl())
        return unauthorized();

      const user = users.find((x) => x.identifier === idFromUrl());
      return ok(user);
    }

    // helper functions

    function ok(body) {
      return of(new HttpResponse({ status: 200, body }));
    }

    function unauthorized() {
      return throwError({ status: 401, error: { message: "unauthorized" } });
    }

    function error(message) {
      return throwError({ status: 400, error: { message } });
    }

    function isLoggedIn() {
      const authHeader = headers.get("Authorization") || "";
      return authHeader.startsWith("Bearer fake-jwt-token");
    }

    function isAdmin() {
      return isLoggedIn() && currentUser().role === Role.Administrador;
    }

    function currentUser() {
      if (!isLoggedIn()) return;
      const id = parseInt(headers.get("Authorization").split(".")[1]);
      return users.find((x) => x.identifier === id);
    }

    function idFromUrl() {
      const urlParts = url.split("/");
      return parseInt(urlParts[urlParts.length - 1]);
    }
  }
}

export const fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true,
};
