import { Component } from '@angular/core';
import { Spinners4lService } from '../spinners4l.service';

@Component({
  selector: 'app-spinners4l',
  template: `
  
  <div class="overlay" *ngIf="isLoading$ | async ">
  
  <div id="loading-bg">
      <div class="loading-logo">
        <img src="assets/images/logo/logo-10care.png" alt="Logo" />
      </div>
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  
  </div>

  
    `,
  styleUrls: ['./spinners4l.component.scss']
})
export class Spinners4lComponent {
  isLoading$ = this.spinnerSvc.isLoading$
  constructor(private spinnerSvc:Spinners4lService) { }

  

}
