import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "collapsible",
    role: ["Gestor de Casos","Enfermera","Gestor","Gestor Javesalud"], //? To hide collapsible based on user role
    icon: "home",
    children: [
      {
        id: 'home',
        title: 'Home',
        translate: 'MENU.HOME',
        type: 'item',
        icon: 'circle',
        role: ["Gestor de Casos","Enfermera","Gestor","Gestor Javesalud"],
        url: 'pages/dashboard/home'
      },
     /*  {
        id: "gestion",
        title: "Cuadro Gestión",
        type: "item",
        role: ["Gestor de Casos","Enfermera"], //? To set multiple role: ['Admin', 'Client']
        icon: "circle",
        url: "dashboard/gestion",
      }, */
      
      {
        id: "gestionino",
        title: "Cuadro de Gestión",
        type: "item",
        role: ["Gestor de Casos","Enfermera","Gestor","Gestor Javesalud"],
        icon: "circle",
        url: "pages/dashboard/gestion",
      },
      {
        id: "alertas",
        title: "Alertas",
        type: "item",
        role: ["Gestor de Casos","Enfermera","Gestor","Gestor Javesalud"], //? To set multiple role: ['Admin', 'Client']
        icon: "alert-triangle",
        url: "pages/dashboard/alertas",
      },
      {
        id: "proyecciones",
        title: "Proyecciones",
        type: "item",
        role: ["Gestor de Casos","Enfermera","Gestor","Gestor Javesalud"], //? To set multiple role: ['Admin', 'Client']
        icon: "bar-chart",
        url: "pages/dashboard/proyecciones",
      },
      {
        id: "report-download",
        title: "Reporte",
        type: "item",
        role: ["Gestor de Casos","Enfermera","Gestor","Gestor Javesalud"], //? To set multiple role: ['Admin', 'Client']
        icon: "bar-chart",
        url: "pages/dashboard/report-download",
      },
      {
        id: "gtareas",
        title: "Gestionar Tareas",
        type: "item",
        role: ["Gestor de Casos","Enfermera","Gestor"], //? To set multiple role: ['Admin', 'Client']
        icon: "circle",
        url: "pages/dashboard/gestiontareas",
      },
    ],
  },
  {
    id: "param",
    type: "section",
    role: ["Gestor de Casos","Gestor","Gestor Javesalud"],
    title: "Parametrización",
    icon: "package",
    children: [
      {
        id: "rutaaten",
        title: "Rutas de Atención",
        type: "collapsible",
        role: ["Gestor de Casos","Gestor","Gestor Javesalud"],
        icon: "corner-up-right",
        children: [
          {
            id: "crearruta",
            title: "Crear Ruta",
            type: "item",
            role: ["Gestor de Casos","Gestor"],
            icon: "circle",
            url: "pages/rutas-de-atencion/create-route",
          },
          {
            id: "editarruta",
            title: "Editar Ruta",
            type: "item",
            role: ["Gestor de Casos","Gestor"],
            icon: "circle",
            url: "pages/rutas-de-atencion/edit-route",
          },
          {
            id: "eliminarruta",
            title: "Eliminar Ruta",
            type: "item",
            role: ["Gestor de Casos","Gestor"],
            icon: "circle",
            url: "pages/rutas-de-atencion/delete-route",
          },
          {
            id: "verruta",
            title: "Ver Ruta",
            type: "item",
            role: ["Gestor de Casos","Gestor","Gestor Javesalud"],
            icon: "circle",
            url: "pages/rutas-de-atencion/see-route",
            // collapsed: true
          },
        ],
      },
      {
        id: "indicadores",
        title: "Indicadores",
        type: "collapsible",
        role: ["Gestor de Casos","Gestor"],
        icon: "activity",
        children: [
          {
            id: "crearvariable",
            title: "Crear Variable",
            type: "item",
            role: ["Gestor de Casos","Gestor"],
            icon: "circle",
            url: "pages/indicadores/variable",
          },
          {
            id: "categoria",
            title: "Categorías",
            type: "item",
            role: ["Gestor de Casos","Gestor"],
            icon: "circle",
            url: "pages/indicadores/categorias",
          },
          {
            id: "order-variable",
            title: "Ordenar Variable",
            type: "item",
            role: ["Gestor de Casos","Gestor"],
            icon: "circle",
            url: "pages/indicadores/order-variable",
          },
          /* {
            id: "crearvariablepoblacional",
            title: "Crear Variable Poblacional",
            type: "item",
           role: ["CaseManager"],
            icon: "circle",
            url: "pages/variable-population",
          }, */
          {
            id: "crearindicador",
            title: "Crear Indicador",
            type: "item",
            role: ["Gestor de Casos","Gestor"],
            icon: "circle",
            url: "pages/indicadores/indicator",
          },
          /*
          {
            id: 'ex-drag-drop',
            title: 'Drag & Drop',
            translate: 'MENU.UI.EX.DRAGDROP',
            icon: 'circle',
            type: 'item',
            url: 'extensions/drag-drop'
          }, */
          
        ],
      },
      {
        id: "alertas",
        title: "Alertas",
        type: "collapsible",
        role: ["Gestor de Casos","Gestor"],
        icon: "alert-triangle",
        children: [
          
          {
            id: "confalert",
            title: "Configurar Alertas",
            type: "item",
            role: ["Gestor de Casos","Gestor"],
            icon: "circle",
            url: "pages/alertas/alert",
          },
        ],
      },
      {
        id: "pacientes",
        title: "Pacientes",
        type: "collapsible",
        role: ["Gestor de Casos","Enfermera","Gestor","Gestor Javesalud"],
        icon: "users",
        children: [
          {
            id: "list-patients",
            title: "Listar Pacientes",
            type: "item",
           role: ["Gestor de Casos","Enfermera","Gestor","Gestor Javesalud"],
            icon: "circle",
            url: "pages/patients/list-patients",
          },
          {
            id: "create-patients",
            title: "Crear Pacientes",
            type: "item",
            role: ["Gestor de Casos","Enfermera","Gestor","Gestor Javesalud"],
            icon: "circle",
            url: "pages/patients/create-patients",
          },
          {
            id: "manage-patients",
            title: "Gestionar Pacientes ",
            type: "item",
            role: ["Enfermera","Gestor","Gestor de Casos","Gestor Javesalud"],
            icon: "circle",
            url: "pages/patients/manage-patients",
          },
        ],
      }
    ]
  },
]
