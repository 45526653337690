import { Component, OnInit } from '@angular/core';
import { takeUntil, first } from "rxjs/operators";
import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  public countuser:Number = 0;
  public error:String;
  public currentUser: User;
  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(private _notificationsService: NotificationsService,private _authenticationService: AuthenticationService) {
    this._authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this.fnCountTask();
    this._notificationsService.disparadorNotify.subscribe(data=>{
     // console.log('Recibiendo Notificación',data);
      this.fnCountTask();
    })
    
    this._notificationsService.onApiDataChange.subscribe(res => {
      this.notifications = res;
    });

    //this.fnCountTask();
  }


  fnCountTask(){

    let param ={
      identifier_user : this.currentUser.identifier,
      identifier_business : this.currentUser.identifier_business,
      identifier_user_responsable : this.currentUser.identifier
    }
      
     // console.log(param);
      
      this._notificationsService
        .getCountTask(param)
        .pipe(first())
        .subscribe(
          (data) => {
          
           this.countuser = data
        
            
          },
          (error) => {
            this.error = error;
          }
        );

  }
}
