import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Spinners4lComponent } from './spinners4l.component';



@NgModule({
  declarations: [
    Spinners4lComponent
  ],
  imports: [
    CommonModule
  ],exports:[Spinners4lComponent]
})
export class Spinners4lModule { }
