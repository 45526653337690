import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { Spinners4lService } from "../spinners4l.service";

@Injectable()
export class Spinners4linterceptor implements HttpInterceptor {
  constructor(private SpinnerSvc: Spinners4lService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.SpinnerSvc.show();
    return next.handle(req).pipe(finalize(() => this.SpinnerSvc.hide()));
  }
}
