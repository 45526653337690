import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import Swal from "sweetalert2";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _toastrService: ToastrService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    return next.handle(request).pipe(
      
      catchError((err) => {
        
        if ([401, 403,404].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          var tipoerror = err.status;

          switch (tipoerror) {
            case 401:
              this._router.navigate(["/pages/authentication/login-v2"]);
              break;
            case 403:
              console.log(err);

              Swal.fire({
                icon: "error",
                title: err.error.message,
                showConfirmButton: true,
                timer: 5000,
              });

              break;
              case 404:
              console.log(err);

              Swal.fire({
                icon: "error",
                title: err.error.message,
                showConfirmButton: true,
                timer: 5000,
              });

              break;
            default:
              //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
              this._router.navigate(["/pages/miscellaneous/not-authorized"]);
          }

          //

          // ? Can also logout and reload if needed
          // this._authenticationService.logout();
          // location.reload(true);
        }
        // throwError
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
