import { Role } from "./role";

export class User {
  identifier_type: String;
  identifier: number;
  email: string;
  password: string;
  name: string;
  last_name: string;
  avatar: string;
  role: Role;
  token?: string;
  identifier_business: number;
  identifier_campus: Number;
  status: string;
  identifier_role: Number;
  name_business:string
}
