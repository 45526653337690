import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from "environments/environment";
import { BehaviorSubject } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;


  @Output() disparadorNotify:EventEmitter<any>= new EventEmitter();


  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this.onApiDataChange = new BehaviorSubject('');
    // this.getNotificationsData();
  }

  /**
   * Get Notifications Data
   */
  getNotificationsData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/notifications-data').subscribe((response: any) => {
        this.apiData = response;
        this.onApiDataChange.next(this.apiData);
        resolve(this.apiData);
      }, reject);
    });
  }

  getCountTask(data) {
    return this._httpClient
      .post<any>(`${environment.apiUrl}/counttaskresponsable`, data)
      .pipe(
        map((count) => {
          // login successful if there's a jwt token in the response
         
          if (count) {
            //console.log(user);
            return count.resultTaskResponsable;
          }
        })
      );
  }




}
